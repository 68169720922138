<template>
  <el-dialog
    title="批量补交账"
    :visible.sync="showDialog"
    width="600px"
    top="16vh"
    :close-on-click-modal="false"
    :modal="false"
  >
    <div class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="120px"
        label-suffix=":"
      >
        <el-form-item label="未交账合计" prop="">
          <el-input v-model="form.money" disabled></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="按从远及近补交" prop="">
          <el-input v-model="form.pay_money" type="number"></el-input>
        </el-form-item>
        <div></div>
        <el-form-item label="入账账户" prop="">
          <el-select
            v-model="form.pay_type"
            clearable
            style="width: 140px"
            placeholder="请选择入账账户"
          >
            <el-option
              v-for="(i, idx) in zhList"
              :key="idx"
              :value="i.id"
              :label="i.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="dialog-footer">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="showDialog = false">关闭</el-button>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import { fkzhSelect } from '@/api/purchase'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'WangQiDialog',
    components: {},
    data() {
      return {
        showDialog: false,
        form: {},
        zhList: [],
      }
    },
    computed: {},
    watch: {
      showDialog(v) {
        if (v) {
          this.initSelect()
        }
      },
    },
    created() {},
    mounted() {},
    methods: {
      initSelect() {
        fkzhSelect().then((r) => {
          this.zhList = r.data
        })
      },
      handleSubmit() {
        postAction('/saleAdmin/duizhang/supply-submit', this.form).then((r) => {
          this.$emit('refresh')
          this.showDialog = false
          this.$message.success(r.msg)
        })
      },
    },
  }
</script>
<style lang="scss" scoped></style>
