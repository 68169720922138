<!--
 * @Author: your name
 * @Date: 2020-12-30 18:04:04
 * @LastEditTime: 2021-03-30 16:42:01
 * @LastEditors: Please set LastEditors
 * @Description: 往期
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\components\Wangqi.vue
-->
<template>
  <div>
    <el-dialog
      title="往期历史"
      :visible.sync="dialogVisible"
      center
      width="65%"
      :modal="false"
      style="margin-top: -10vh"
    >
      <div>
        <el-row style="padding: 20px 0px">
          <el-col :span="18">
            <el-button type="primary" @click="dialogVisible = false">
              返回列表
            </el-button>
          </el-col>
        </el-row>
        <el-table
          ref="table"
          v-loading="loading"
          stripe
          row-key="id"
          :data="tabledata"
          @row-click.self="handlerrowclick"
          @selection-change="handleChangeSelect"
        >
          <el-table-column
            :reserve-selection="true"
            type="selection"
          ></el-table-column>
          <el-table-column
            label="ID"
            align="center"
            prop="id"
          ></el-table-column>
          <el-table-column
            label="对账时间"
            align="center"
            prop="date"
          ></el-table-column>
          <el-table-column
            label="对账人"
            align="center"
            prop="add_name"
          ></el-table-column>
          <el-table-column
            label="本期应交账"
            align="center"
            prop="account_payable"
          ></el-table-column>
          <el-table-column
            label="本期实交账"
            align="center"
            prop="account_paid"
          ></el-table-column>
          <el-table-column
            label="本期未交账"
            align="center"
            prop="account_unpaid"
          ></el-table-column>
          <el-table-column label="操作" align="center" prop="">
            <template slot-scope="scope">
              <el-popconfirm
                title="您真的要作废吗？"
                @confirm="handlerzuofei(scope.row)"
              >
                <el-button
                  v-show="scope.row.can_scrap == 1"
                  slot="reference"
                  type="text"
                  @click.stop=""
                >
                  作废
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-button type="primary" style="margin-top: 10px" @click="batchSub">
          批量补交对账
        </el-button>
        <el-pagination
          :current-page="pageNo"
          :layout="layout"
          :page-size="pageSize"
          :total="totalCount"
          background
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
        ></el-pagination>
      </div>
      <wang-qi-dialog
        ref="wangQiDialog"
        @refresh="handlerData(rowinfo)"
      ></wang-qi-dialog>
    </el-dialog>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import WangQiDialog from './wangQiDialog.vue'
  export default {
    components: { WangQiDialog },
    data() {
      return {
        currentRow: [],
        dialogVisible: false,
        tabledata: [],
        rowinfo: {},
        loading: false,
        layout: 'total, prev, pager, next',
        totalCount: 0,
        pageNo: 1,
        pageSize: 10,
      }
    },
    watch: {
      dialogVisible(v) {
        if (!v) {
          this.currentRow = []
          this.$refs.table.clearSelection()
        }
      },
    },
    methods: {
      // 分页
      handleCurrentChange(val) {
        this.pageNo = val
        this.handlerData(this.rowinfo)
      },
      handleSizeChange(val) {
        this.pageSize = val
        this.handlerData(this.rowinfo)
      },
      handlerrowclick(e) {
        console.log(e, this.rowinfo, '每一行的数据')
        // this.rowinfo.id = e.id
        e.duizhang_id = this.rowinfo.duizhang_id
        console.log(
          '截取时间',
          e.date.slice(e.date.indexOf('--') + 2),
          e.date.slice(0, e.date.indexOf('--'))
        )
        this.$emit('duiz', e)
      },
      handlerzuofei(row) {
        console.log(row)
        postAction('/saleAdmin/duizhang/scrap', {
          // duizhang_id: row.duizhang_id,
          duizhang_id: row.id,
        })
          .then((res) => {
            console.log(res, '')
            this.$message({
              type: 'success',
              message: res.msg,
            })
            this.handlerData(this.rowinfo)
            this.$emit('refresh')
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerData(row) {
        this.loading = true
        console.log(row, '')
        this.rowinfo = row
        postAction('/saleAdmin/duizhang/log', {
          user_id: row.id,
          is_hidescrap: 0,
          pageSize: this.pageSize,
          pageNo: this.pageNo,
        })
          .then((res) => {
            console.log(res, '')
            this.tabledata = res.data.list
            this.totalCount = res.totalCount
            this.loading = false
          })
          .catch((err) => {
            console.log(err, '')
            this.loading = false
          })
      },
      // 批量补交对账
      batchSub() {
        if (this.currentRow.length == 0) {
          this.$message.error('请选择对账信息')
        } else {
          let ids = []
          let weiM = 0
          this.currentRow.forEach((i) => {
            ids.push(i.id)
            weiM += Number(i.account_unpaid)
          })
          this.$refs.wangQiDialog.form = {
            duizhang_ids: ids.join(','),
            pay_money: '',
            money: weiM,
            pay_type: '',
          }
          this.$refs.wangQiDialog.showDialog = true
          // postAction('/saleAdmin/duizhang/supply-submit', {
          //   duizhang_ids: ids.join(','),
          //   pay_money: '',
          //   pay_type: '',
          // })
        }
      },
      handleChangeSelect(rows) {
        this.currentRow = rows
      },
    },
  }
</script>

<style></style>
