<!--
 * @Author: 候怀烨
 * @Date: 2020-11-11 14:09:29
 * @LastEditTime: 2021-02-24 15:03:31
 * @LastEditors: Please set LastEditors
 * @Description: 销售对账
 * @FilePath: \sd-vue-admin\src\views\project\sale\control\sale\index.vue
-->
<template>
  <!-- NAME[epic=销售] 销售对账 -->
  <div class="wrapper">
    <div class="top">
      <el-form :model="form" inline>
        <el-form-item>
          <el-select
            v-model="form.depart_id"
            placeholder="请选择部门"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(list, index) in department"
              :key="index"
              :label="list.text"
              :value="list.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.role"
            placeholder="请选择角色"
            clearable
            style="width: 120px"
          >
            <el-option
              v-for="(lists, index) in Roles"
              :key="index"
              :label="lists.role_name"
              :value="lists.role_id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="form.keyword"
            placeholder="请输入姓名电话"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.status"
            clearable
            placeholder="请选择状态"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in type"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handlerData">查 询</el-button>
        </el-form-item>
        <!-- <el-form-item>
          <el-popover placement="top" width="400" trigger="click">
            <p>待对账：员工当前可以进行新一期的对账</p>
            <p>对账中：对账正在进行中</p>
            <div slot="reference">
              <i class="el-icon-warning color" style="color: #f60"></i>
            </div>
          </el-popover>
        </el-form-item> -->
      </el-form>
    </div>
    <div class="heard">
      <el-table v-loading="loading" stripe :data="tabledata">
        <el-table-column
          v-for="(item, index) in celmer"
          :key="index"
          :align="item.align"
          :label="item.label"
          width=""
          :prop="item.prop"
        ></el-table-column>
        <el-table-column label="操作" align="center" width="150" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-show="scope.row.status == 2"
              type="text"
              size="small"
              @click="handleClick(scope.row, 1)"
            >
              对账
            </el-button>
            <el-button
              v-show="scope.row.status == 1"
              type="text"
              size="small"
              @click="handleClick(scope.row, 2)"
            >
              继续
            </el-button>
            <el-button type="text" size="small" @click="wangqi(scope.row)">
              往期
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <reconciliation
      ref="Reconciliation"
      @refresh="handlerData"
    ></reconciliation>
    <wangqi ref="wang" @duiz="wangqiinfo" @refresh="handlerData"></wangqi>
  </div>
</template>
<script>
  import Wangqi from './components/Wangqi.vue'
  import { dateForm } from '@/utils/Time'
  import { postAction } from '@/api/Employee'
  import Reconciliation from './components/duizhang.vue'
  // import Template from '@/views/project/admin/systemManagement/adminAccount/components/template.vue'
  export default {
    name: 'Sale2',
    components: {
      Reconciliation,
      Wangqi,
      // Template,
    },
    data() {
      return {
        loading: false,
        form: {
          depart_id: '',
          role: '',
          status: '',
          keyword: '',
        },
        url: {
          department: '/baseAdmin/depart/index',
          Roles: '/baseAdmin/common/role-list',
          data: '/saleAdmin/duizhang/index',
          duizhang: '/saleAdmin/duizhang/un-base-detail',
          jixu: '/saleAdmin/duizhang/base-detail',
        },
        department: [],
        Roles: [],
        // 1对账中 2待对账
        type: [
          {
            id: '1',
            name: '对账中',
          },
          {
            id: '2',
            name: '待对账',
          },
        ],
        celmer: [
          {
            align: 'center',
            label: '姓名',
            width: '',
            prop: 'user_name',
          },
          {
            align: 'center',
            label: '角色',
            width: '',
            prop: 'user_role',
          },
          {
            align: 'center',
            label: '电话',
            width: '',
            prop: 'mobile',
          },
          {
            align: 'center',
            label: '最近对账时间',
            width: '',
            prop: 'end_time',
          },
          {
            align: 'center',
            label: '状态',
            width: '',
            prop: 'status_txt',
          },
        ],
        tabledata: [],
      }
    },
    mounted() {
      this.handlerDepartment()
      this.handlerRoles()
      // this.handlerData()
      // 获取url参数
      console.log('获取url参数', this.$route.query.status)
      if (this.$route.query.status) {
        this.form.status = this.$route.query.status
        this.handlerData()
      } else {
        this.handlerData()
      }
    },
    activated() {
      this.handlerData()
    },
    methods: {
      //往期
      wangqi(row) {
        this.$refs.wang.dialogVisible = true
        this.$refs.wang.handlerData(row)
      },
      handlersuaxin() {
        this.handlerData()
      },
      //往期里面的每一行详情
      wangqiinfo(row) {
        console.log(row)
        this.handleClick(row, 3)
      },
      //对账中
      handleClick(row, dui) {
        console.log('点击', dui)
        // console.log((this.$refs.Reconciliation.dialogVisible = true), '对账中')
        if (dui == 1) {
          // 对账
          console.log(row, '对账')
          var date = new Date()
          console.log(dateForm(date), '')
          this.$refs.Reconciliation.type = dui
          this.$refs.Reconciliation.id = row.id
          this.$refs.Reconciliation.duizhang_id = row.duizhang_id
          this.$refs.Reconciliation.endTime = dateForm(date)
          // this.$refs.Reconciliation.startTime = row.end_time
          this.$refs.Reconciliation.showDialog = true
          // postAction(this.url.duizhang, {
          //   user_id: row.id,
          //   end_time: dateForm(date),
          // })
          //   .then((res) => {
          //     console.log(res, '')
          //     // this.$refs.Reconciliation.handlerData(res.data, row, dui)
          //     console.log(this.$refs.Reconciliation, '')
          //   })
          //   .catch((err) => {
          //     console.log(err, '')
          //   })
          // setTimeout(() => {
          //   this.$refs.Reconciliation.dialogVisible = true
          // }, 400)
        } else if (dui == 2) {
          // 继续
          console.log(row, '继续')
          this.$refs.Reconciliation.type = dui
          this.$refs.Reconciliation.id = row.id
          this.$refs.Reconciliation.duizhang_id = row.duizhang_id

          var date = new Date()
          this.$refs.Reconciliation.endTime = dateForm(date)
          // this.$refs.Reconciliation.startTime = row.end_time

          this.$refs.Reconciliation.showDialog = true
          // postAction(this.url.jixu, {
          //   duizhang_id: row.duizhang_id,
          // })
          //   .then((res) => {
          //     console.log(res, '')
          //     this.$refs.Reconciliation.handlerData(res.data, row, dui)
          //   })
          //   .catch((err) => {
          //     console.log(err, '')
          //   })
          // setTimeout(() => {
          //   this.$refs.Reconciliation.dialogVisible = true
          // }, 100)
          //往期对账
        } else if (dui == 3) {
          console.log('3走的这个')
          console.log('rrr', row.id)
          this.$refs.Reconciliation.type = 2
          this.$refs.Reconciliation.id = row.id
          this.$refs.Reconciliation.duizhang_id = row.id

          this.$refs.Reconciliation.caozuo = 2
          // this.$refs.Reconciliation.duizhang_id = row.duizhang_id
          this.$refs.Reconciliation.endTime = row.date.slice(
            row.date.indexOf('--') + 2
          )
          this.$refs.Reconciliation.startTime = row.date.slice(
            0,
            row.date.indexOf('--')
          )

          this.$refs.Reconciliation.showDialog = true
        }
      },
      handlerData() {
        this.loading = true
        postAction(this.url.data, this.form)
          .then((res) => {
            console.log(res, '')
            this.tabledata = res.data
            this.loading = false
          })
          .catch((err) => {
            console.log(err, '')
            this.loading = false
          })
      },
      handlerRoles() {
        postAction(this.url.Roles, {})
          .then((res) => {
            console.log(res, '')
            this.Roles = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      handlerDepartment() {
        postAction(this.url.department, { is_close: 0 })
          .then((res) => {
            console.log(res, '')
            this.department = res.data.list
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
    },
  }
</script>
<style lang="css" scoped>
  .wrapper {
    padding: 20px;
  }
</style>
<style>
  .color {
    font-size: 30px;
    color: #f8cb04;
  }
</style>
